
import {
  Asset,
  AssetStatusFilter,
  RawObservationRecord,
  SYSTEM_TYPE,
} from '../../config/types';
import {
  checkIsFilteredByDate,
  checkIsFilteredByLayer,
  checkIsFilteredByObservationType,
  checkIsFilteredByStatus,
  checkIsFilteredBySupplier,
  checkIsFilteredByTable,
  checkSearchFilter,
  getDataSourceAssetFromAsset,
  getObservationSameOrBeforeTimeTravelDate,
} from '../../utils';
import {
  getAssetStatusesKeyedById,
  getAssetsArray,
  getCurrentFeatureFilters,
  getCustomColumns,
  getDefaultAssetStatus,
  getLayerIdsKeyedByAncestorId,
  getLinkedDevicesKeyedByAssetId,
  getListViewColumns,
} from '..';
import { FeatureFilterType } from '../../context';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import map from '../map';
import some from 'lodash/some';

const getTimeTravelAssetsArray = createSelector(
  [
    getCurrentFeatureFilters,
    getAssetsArray,
    getDefaultAssetStatus,
  ],
  (
    currentFeatureFilters,
    assetsArray,
    defaultStatus,
  ) => {
    const { timeTravelDate } = currentFeatureFilters;
    if (!timeTravelDate) return assetsArray;
    // If the default status (usually "Added to Ynomia") is in the filter, allow all assets
    return assetsArray.map((asset: Asset) => {
      const newAsset = { ...asset } as Asset;
      const filteredObservations: RawObservationRecord[] =
        getObservationSameOrBeforeTimeTravelDate(asset, timeTravelDate);

      const filteredObservationsStatusUpdate = filteredObservations.filter(
        ({ type }) =>
          type === SYSTEM_TYPE.AUTOMATIC_STATUS_UPDATE_OBSERVATION
          || type === SYSTEM_TYPE.STATUS_UPDATE_OBSERVATION,
      );

      newAsset.observations = filteredObservations;

      if (filteredObservationsStatusUpdate[0]?.to) {
        const lastStatusUpdate = filteredObservationsStatusUpdate.find(
          ({ isOutOfSequence }) => !isOutOfSequence,
        ) || filteredObservationsStatusUpdate[0];
        newAsset.status = lastStatusUpdate.to;

        newAsset.statusUpdatedDate = dayjs(lastStatusUpdate.date).toDate();
      } else {
        newAsset.status = defaultStatus?.id;
        newAsset.statusUpdatedDate = dayjs(asset.created).toDate();
      }

      return newAsset;
    });
  },
);

export const getTimeTravelAssetsById = createSelector(
  [getTimeTravelAssetsArray],
  timeTravelAssetsArray => new Map(
    timeTravelAssetsArray.map(asset => [asset.id, asset]),
  ) as Map<string, Asset>,
);

const getObservationTypesKeyedByAssetId = createSelector(
  [getTimeTravelAssetsArray],
  assetsArray => new Map(
    assetsArray.map(({ id, observations }) => [id, observations?.map(({ type }) => type)]),
  ),
);

export const getDataSourceKeyedByAssetId = createSelector(
  [
    map.layersKeyedById,
    map.assetsKeyedById,
    getCustomColumns,
    getAssetsArray,
    getAssetStatusesKeyedById,
    getLinkedDevicesKeyedByAssetId,
  ],
  (
    layersKeyedById,
    assetsKeyedById,
    customColumns,
    assetsArray,
    assetStatusesKeyedById,
    linkedDevicesKeyedByAssetId,
  ) => {
    return new Map(assetsArray.map(
      (asset: Asset) =>  [
        asset.id,
        getDataSourceAssetFromAsset(
          asset,
          customColumns,
          layersKeyedById,
          assetsKeyedById,
          assetStatusesKeyedById,
          linkedDevicesKeyedByAssetId,
        ),
      ],
    ));
  },
);

export const getFilteredAssetsContext = createSelector(
  [
    map.layersKeyedById,
    getLinkedDevicesKeyedByAssetId,
    getTimeTravelAssetsArray,
    getAssetStatusesKeyedById,
    getListViewColumns,
    getLayerIdsKeyedByAncestorId,
    getObservationTypesKeyedByAssetId,
    getDataSourceKeyedByAssetId,
  ],
  (
    layersKeyedById,
    linkedDevicesKeyedByAssetId,
    assetsArray,
    assetStatusesKeyedById,
    listViewColumns,
    layerIdsKeyedByAncestorId,
    observationTypesKeyedByAssetId,
    listViewDataSource,
  ) => {
    return {
      layersKeyedById,
      linkedDevicesKeyedByAssetId,
      assetsArray,
      assetStatusesKeyedById,
      layerIdsKeyedByAncestorId,
      observationTypesKeyedByAssetId,
      listViewColumns,
      listViewDataSource,
    };
  },
);

export const getFilteredAssets = (
  {
    layersKeyedById,
    linkedDevicesKeyedByAssetId,
    assetsArray,
    assetStatusesKeyedById,
    listViewColumns,
    layerIdsKeyedByAncestorId,
    observationTypesKeyedByAssetId,
    listViewDataSource,
  }: ReturnType<typeof getFilteredAssetsContext>,
  {
    searchTags,
    searchText,
    dateFilter,
    typeFilter,
    assetStatusFilter,
    observationTypeFilter,
    assetLayerFilter,
    tableFilter,
    layerChildFilter,
    supplierFilter,
    multiSearchModeFilter,
  }: Partial<FeatureFilterType>,
): Array<Asset> => {
  const defaultStatus =  [...assetStatusesKeyedById.values()].find(s => s.default);
  const observationTypeFilterIds = observationTypeFilter?.size || 0;
  const filteredAssets = assetsArray
    .filter((asset: Asset) => {
      const { destination } = asset;
      const layerID = destination?.layerID as string;
      const dataSourceAsset = listViewDataSource.get(asset.id) || {};

      const isFilteredBySearch = () =>
        (!searchTags?.length && !searchText)
          || checkSearchFilter(
            searchTags || [],
            searchText,
            multiSearchModeFilter,
            dataSourceAsset,
          );
      const isFilteredByType = () => !typeFilter || typeFilter === asset.type;
      const isFilteredByDate = () => !(dateFilter?.length === 2)
        || checkIsFilteredByDate(asset, dateFilter, assetStatusFilter);
      const isFilteredByStatus = () => !assetStatusFilter?.ids.length || checkIsFilteredByStatus(
        asset,
        assetStatusesKeyedById,
        assetStatusFilter as AssetStatusFilter,
        defaultStatus,
      );

      const isFilteredByLevel = () => !assetLayerFilter?.length
        || assetLayerFilter.includes(layerID);
      const isFilteredByLayer = () =>
        (!layerChildFilter) ||
        checkIsFilteredByLayer(
          layerChildFilter,
          layersKeyedById,
          layerID,
          layerIdsKeyedByAncestorId,
        );

      const isTableFilterExist = () => some(tableFilter, value => !!value?.length);
      const isFilteredByObservationType = () =>
        !observationTypeFilterIds
      || checkIsFilteredByObservationType(
        asset,
        observationTypeFilter,
        observationTypesKeyedByAssetId,
      );

      const isFilteredByTable = () =>
        !isTableFilterExist ||
        checkIsFilteredByTable(
          asset,
          linkedDevicesKeyedByAssetId,
          tableFilter,
          listViewColumns,
          dataSourceAsset,
        );

      const isFilteredBySupplier = () =>
        (supplierFilter?.length === 0 || supplierFilter === undefined)
        || checkIsFilteredBySupplier(asset, supplierFilter);

      return isFilteredBySearch()
        && isFilteredByDate()
        && isFilteredByType()
        && isFilteredByStatus()
        && isFilteredByLevel()
        && isFilteredByLayer()
        && isFilteredByTable()
        && isFilteredBySupplier()
        && isFilteredByObservationType();
    });
  return filteredAssets;
};

export const getAssetFiltersActive = createSelector(
  [
    getCurrentFeatureFilters,
  ],
  (
    currentFeatureFilters,
  ) => {
    const {
      searchTags,
      assetStatusFilter,
      tableFilter,
      layerChildFilter,
      assetLayerFilter,
      tableSorter,
      supplierFilter,
      observationTypeFilter,
    } = currentFeatureFilters;

    return !!(
      searchTags?.length
      || assetStatusFilter?.toggle
      || !isEmpty(tableFilter)
      || layerChildFilter
      || assetLayerFilter?.length
      || tableSorter
      || supplierFilter?.length
      || observationTypeFilter?.size
    );
  },
);
