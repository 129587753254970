import { getCurrentFeatureFilters, getObservationTypes } from '..';
import { getFilteredAssets, getFilteredAssetsContext } from './index';

import { createSelector } from 'reselect';
import { roundValue } from '@ynomia/core/dist/utils';

const getFilteredAssetsForObservationSummary = createSelector(
  [
    getCurrentFeatureFilters,
    getFilteredAssetsContext,
  ],
  (
    currentFeatureFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      assetLayerFilter,
      tableFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    } = currentFeatureFilters;
    const filteredAssets = getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      assetLayerFilter,
      tableFilter,
      layerChildFilter,
      supplierFilter,
      multiSearchModeFilter,
    });
    return filteredAssets.sort((a, b) => String(a.label).localeCompare(String(b.label)));
  },
);

const getObservationsByObservationTypes = createSelector(
  [getFilteredAssetsForObservationSummary],
  (filteredAssetsForObservationSummary) => {
    const assetsByObservationType: Map<string, Array<string>> = new Map();

    filteredAssetsForObservationSummary.forEach((asset) => {
      const recordedTypes = new Set<string>();
      asset.observations?.forEach(({ type }) => {
        // Do not count same obs type multiple times
        if (recordedTypes.has(type)) return;
        recordedTypes.add(type);
        const assetList = assetsByObservationType.get(type);
        if (assetList) {
          assetList.push(asset.id);
        } else {
          assetsByObservationType.set(type, [asset.id]);
        }
      });
    });
    return assetsByObservationType;
  },
);

export const getObservationSummary = createSelector(
  [
    getObservationTypes,
    getFilteredAssetsForObservationSummary,
    getObservationsByObservationTypes,
  ],
  (
    observationTypes,
    filteredAssetsForObservationSummary,
    observationsByObservationTypes,
  ) => {
    const totalObservationAssets = filteredAssetsForObservationSummary.length;

    return observationTypes.map((observationType) => {
      const { id } = observationType;
      const withOccurrence = observationsByObservationTypes.get(id)?.length || 0;
      const withPercentage = roundValue(
        (withOccurrence * 100) / totalObservationAssets,
        1,
      );

      const withoutOccurrence = totalObservationAssets - withOccurrence;
      const withoutPercentage = roundValue(100 - withPercentage, 1);
      return {
        ...observationType,
        withOccurrence,
        withPercentage,
        withoutOccurrence,
        withoutPercentage,
      };
    });
  },
);
